<template>
  <div class="templates-list__body">
    <transition name="fade">
      <div v-if="addingTemplate" class="m-4">
        <span>
          {{ lang.botMaker.journeysTemplates.working[languageSelected] }}
        </span>
        <vs-progress indeterminate color="primary" />
      </div>
    </transition>
    <div class="vs-con-loading__container list" ref="loadingContainer">
      <vs-collapse accordion>
        <vs-collapse-item
          :key="index"
          v-for="(templateName, index) in Object.keys(templatesList)"
        >
          <div slot="header">
            <p>{{ templateName }}</p>
            <small style="color: rgba(0,0,0,.5)">
              {{ templatesList[templateName].description }}
            </small>
          </div>
          <!-- <small class="mb-2">
            {{ templatesList[templateName].description }}
          </small> -->
          <vs-table
            :data="templatesList[templateName].versions"
            v-if="templates.length"
            stripe
          >
            <template slot="thead">
              <vs-th sort-key="name">
                {{
                  lang.botMaker.botEditor.navBar.templates.table.tag[
                    languageSelected
                  ]
                }}
              </vs-th>
              <vs-th></vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].tag">
                  {{ data[indextr].tag }}
                </vs-td>
                <vs-td class="float-right m-2">
                  <vs-button
                    color="primary"
                    type="flat"
                    icon="icon-plus"
                    icon-pack="feather"
                    size="large"
                    :disabled="addingTemplate"
                    @click="
                      addTemplateToBot(
                        tr,
                        templatesList[templateName].serviceId
                      )
                    "
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vs-collapse-item>
      </vs-collapse>
      <div v-if="templates.length === 0 && !searching" class="no-data">
        {{ lang.general.noDataText[languageSelected] }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BotMakerService from '@/services/botMaker.service'

export default {
  name: 'JourneysTemplatesList',
  data() {
    return {
      loaded: false,
      templates: [],
      templatesList: [],
      addingTemplate: false,
      searching: false,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['activeVersion']),
    ...mapGetters('bots', ['bot']),
  },
  methods: {
    async initTemplates() {
      if (this.loaded){
        return
      }

      try {
        this.$vs.loading({ container: this.$refs.loadingContainer, scale: 0.5 })
        this.searching = true

        const culture =
          this.bot &&
          this.bot.nlu &&
          this.bot.nlu.culture &&
          this.bot.nlu.culture.split('-')[0]

        const response = await BotMakerService.getTemplates(culture || '', 'journey')
        this.templates = response && response.data ? response.data : []
        this.templatesList = this.templates.reduce(
          (objectsByKeyValue, obj) => { 
            obj.service = obj.service[0];
            return {
              ...objectsByKeyValue,
              [obj.service.name]: {
                serviceId: obj.service._id,
                description: obj.service.description,
                versions: (objectsByKeyValue[obj.service.name] || []).concat(
                  obj.versions
                )
              }
            }
          },
          {}
        )

        this.$vs.loading.close(this.$refs.loadingContainer)
        this.searching = false
        this.loaded = true
      } catch (error) {
        this.$vs.loading.close(this.$refs.loadingContainer)
        this.searching = false
      }
    },
    async addTemplateToBot(version, tmpServiceId) {
      try {
        this.addingTemplate = true
        const serviceId =
          this.$route.query.service || this.$route.query.serviceId
        const versionId =
          this.$route.query.version || this.$route.query.versionId
        const payload = {
          botVersion: versionId ? versionId : this.activeVersion,
          botService: serviceId,
          templateService: tmpServiceId,
          templateVersion: version._id
        }

        await BotMakerService.addTemplateToBot(payload)
        this.addingTemplate = false
        this.$router.go()
      } catch (error) {
        this.addingTemplate = false
      }
    }
  },
  mounted() {
    // this.initTemplates()
  }
}
</script>

<style lang="scss" scoped>
.templates-list {
  display: flex;
  flex-direction: column;
  min-width: 480px;

  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &::v-deep .con-content--item {
      padding-top: 0 !important;
      margin-bottom: 1rem !important;
    }
    .vs-collapse {
      padding: unset !important;
    }
    .vs-con-loading__container {
      min-height: 150px;
    }
    .list {
      max-height: calc(100vh - 240px);
      overflow-y: auto;

      .no-data {
        padding: 1rem;
      }
    }
    &::v-deep .vs-table--thead, &::v-deep .tr-table{
      .td-check{
        display: none;
      }
    }
  }

  .vs-con-table {
    max-height: 500px;
    overflow-y: auto;
    padding: unset;

    &::v-deep .vs-con-tbody {
      border: none;
      .vs-table--thead {
        th{
          padding-top: .5rem;
          padding-bottom: .5rem; 
          .sort-th{
            display: none;
          }  
        }
      }
      .vs-table--tbody-table .tr-table td{
        padding: 0 1rem;
      }
      .vs-table--tbody-table .tr-table:last-child{
        border-bottom: none;
      }
      .m-2{
        margin: .15rem !important;
      }
    } 

    &::v-deep.stripe .tr-values:nth-child(2n) {
      background-color: inherit !important;
    }
  }
  
}
</style>
