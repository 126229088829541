var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templates-list__body"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.addingTemplate)?_c('div',{staticClass:"m-4"},[_c('span',[_vm._v("\n        "+_vm._s(_vm.lang.botMaker.journeysTemplates.working[_vm.languageSelected])+"\n      ")]),_vm._v(" "),_c('vs-progress',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()]),_vm._v(" "),_c('div',{ref:"loadingContainer",staticClass:"vs-con-loading__container list"},[_c('vs-collapse',{attrs:{"accordion":""}},_vm._l((Object.keys(_vm.templatesList)),function(templateName,index){return _c('vs-collapse-item',{key:index},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('p',[_vm._v(_vm._s(templateName))]),_vm._v(" "),_c('small',{staticStyle:{"color":"rgba(0,0,0,.5)"}},[_vm._v("\n            "+_vm._s(_vm.templatesList[templateName].description)+"\n          ")])]),_vm._v(" "),(_vm.templates.length)?_c('vs-table',{attrs:{"data":_vm.templatesList[templateName].versions,"stripe":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].tag}},[_vm._v("\n                "+_vm._s(data[indextr].tag)+"\n              ")]),_vm._v(" "),_c('vs-td',{staticClass:"float-right m-2"},[_c('vs-button',{attrs:{"color":"primary","type":"flat","icon":"icon-plus","icon-pack":"feather","size":"large","disabled":_vm.addingTemplate},on:{"click":function($event){_vm.addTemplateToBot(
                      tr,
                      _vm.templatesList[templateName].serviceId
                    )}}})],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v("\n              "+_vm._s(_vm.lang.botMaker.botEditor.navBar.templates.table.tag[
                  _vm.languageSelected
                ])+"\n            ")]),_vm._v(" "),_c('vs-th')],1)],2):_vm._e()],1)}),1),_vm._v(" "),(_vm.templates.length === 0 && !_vm.searching)?_c('div',{staticClass:"no-data"},[_vm._v("\n      "+_vm._s(_vm.lang.general.noDataText[_vm.languageSelected])+"\n    ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }