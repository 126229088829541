<template>
  <div id="templates-list" class="templates-list">
    <h4 class="mt-2 ml-4">
      {{ lang.botMaker.journeysTemplates.title[languageSelected] }}
    </h4>
    <KonaTabs :tabsStyle="tabsStyle"
      :returnSelectedTab="true"
      @selectedTab="switchTab">
        <KonaTab
          v-if='this.useCyberbank'
          type="jurney"
          :name="lang.botMaker.journeysTemplates.JourneyTab[languageSelected]"
          :selected="true"><JourneysTemplatesList @hook:mounted="switchTab('jurney')" ref="jurneyTemplate"/>
        </KonaTab>  
        <KonaTab
          type="bot"
          :name="lang.botMaker.journeysTemplates.botTab[languageSelected]"
          :selected="false"><BotTemplatesList ref="botTemplate"/></KonaTab>
         <KonaTab
          type="intent"
          :name="lang.botMaker.journeysTemplates.intentTab[languageSelected]"
          :selected="false"><IntentTemplatesList ref="intentTemplate"/></KonaTab> 
    </KonaTabs>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BotTemplatesList from './templates/BotTemplatesList.vue'
import JourneysTemplatesList from './templates/JourneysTemplatesList.vue'
import IntentTemplatesList from './templates/IntentTemplatesList.vue'


export default {
  name: 'TemplatesList',
  components: {
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    BotTemplatesList,
    JourneysTemplatesList,
    IntentTemplatesList,
  },
  data() {
    return {
      selectedTab: 'jurney'
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'useCyberbank']),
    ...mapGetters('botMaker', ['activeVersion']),
    ...mapGetters('bots', ['bot']),
    tabsStyle() {
      return `
        background: white;
        height: 50px;
        border: 3px solid transparent;
        margin-top: 0;
        border-bottom: 0;
        width: 100%;
      `
    }
  },
  methods: {

    async switchTab(tabIndex) {
      this.selectedTab = tabIndex;
      if (tabIndex == 'jurney'){
        await this.$refs.jurneyTemplate.initTemplates()
      } else if (tabIndex == 'bot') {
        await this.$refs.botTemplate.initTemplates()
      } else {
        await this.$refs.intentTemplate.initTemplates()
      }
    }
  },
  mounted() {
  }
}
</script>
<style lang="scss">
  .templates-list {
    .navigation-tab {
      width: auto !important;
    }
  }
</style>
<style lang="scss" scoped>
.templates-list {
  display: flex;
  flex-direction: column;
  min-width: 480px;
  
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .con-content--item {
      padding: unset !important;
    }
    .vs-collapse {
      padding: unset !important;
    }
    .vs-con-loading__container {
      min-height: 150px;
    }
    .list {
      max-height: calc(100vh - 240px);
      overflow-y: auto;

      .no-data {
        padding: 1rem;
      }
    }
  }

  .vs-con-table {
    max-height: 500px;
    overflow-y: auto;
    padding: unset;
  }
}
</style>
